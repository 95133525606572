import router from '@/router';
import { routes } from '@/router/routes';
import { useRoute } from 'vue-router';
import { educationFundStatuses } from '@/shared/constants/educationFundStatuses';
const { salaries } = require('../../shared/data/salaries');

const mutationNames = {
  updateIncome: 'updateIncome',
  updateEducationFundStatus: 'updateEducationFundStatus',
  updateIncomeInput: 'updateIncomeInput',
  updateTitle: 'updateTitle',
  updateIsFormDisabled: 'updateIsFormDisabled',
  resetState: 'resetState',
};

const actionNames = {
  submitIncome: 'submitIncome',
  updateEducationFundStatus: 'updateEducationFundStatus',
  updateUrlQuery: 'updateUrlQuery',
  updateIncomeInput: 'updateIncomeInput',
  updateTitle: 'updateTitle',
  initState: 'initState',
  raiseIncomeByPercent: 'raiseIncomeByPercent',
  populateCalculatorSalary: 'populateCalculatorSalary',
  populateCalculatorFromQueryParams: 'populateCalculatorFromQueryParams',
};

const calculator = {
  namespaced: true,
  state: () => Object.assign({}, baseState),
  actions: {
    [actionNames.submitIncome]({ commit, dispatch, state }) {
      commit(mutationNames.updateIncome, state.incomeInput);
      dispatch(actionNames.updateUrlQuery);
    },
    [actionNames.updateEducationFundStatus]({ commit, dispatch }, status) {
      commit(mutationNames.updateEducationFundStatus, status);
      dispatch(actionNames.updateUrlQuery);
    },
    [actionNames.updateUrlQuery]({ getters }) {
      router.push({
        path: routes.calculatorResult.path,
        query: getters.urlQuery,
      });
    },
    async [actionNames.initState]({ commit, dispatch }) {
      commit('resetState');
      const currentRouteName = router.currentRoute.value.name;
      const salary = salaries.find((x) => x.key == currentRouteName);
      if (salary) {
        dispatch(actionNames.populateCalculatorSalary, salary);
      } else {
        dispatch(actionNames.populateCalculatorFromQueryParams);
      }
    },
    [actionNames.updateIncomeInput]({ commit }, incomeInput) {
      commit(mutationNames.updateIncomeInput, incomeInput);
    },
    [actionNames.updateTitle]({ commit }, title) {
      commit(mutationNames.updateTitle, title);
    },
    [actionNames.raiseIncomeByPercent]({ commit, state, dispatch }, percent) {
      const multiplier = 1 + percent / 100;
      const newIncome = Math.round(state.income * multiplier);
      if (newIncome > 999999) {
        return;
      }
      commit(mutationNames.updateIncomeInput, newIncome);
      dispatch(actionNames.submitIncome);
    },
    [actionNames.populateCalculatorSalary]({ commit }, salary) {
      commit(mutationNames.updateTitle, salary.title);
      commit(mutationNames.updateIncomeInput, salary.income);
      commit(
        mutationNames.updateEducationFundStatus,
        salary.educationFundStatus
      );
      commit(mutationNames.updateIsFormDisabled, true);
      commit(mutationNames.updateIncome, salary.income);
    },
    [actionNames.populateCalculatorFromQueryParams]({ commit, dispatch }) {
      const queryParams = getCalculatorQueryParams();
      if (queryParams.income) {
        commit(mutationNames.updateIncomeInput, queryParams.income);
        if (queryParams.educationFundStatus) {
          commit(
            mutationNames.updateEducationFundStatus,
            queryParams.educationFundStatus
          );
        }
        dispatch(actionNames.submitIncome);
      }
    },
  },
  mutations: {
    [mutationNames.updateIncome](state, value) {
      state.income = value;
    },
    [mutationNames.updateEducationFundStatus](state, value) {
      state.educationFundStatus = value;
    },
    [mutationNames.updateIncomeInput](state, value) {
      state.incomeInput = value;
    },
    [mutationNames.updateTitle](state, value) {
      state.title = value;
    },
    [mutationNames.updateIsFormDisabled](state, value) {
      state.isFormDisabled = value;
    },
    [mutationNames.resetState](state) {
      Object.assign(state, baseState);
    },
  },
  getters: {
    isIncomeSubmitted(state) {
      return state.income !== undefined;
    },
    urlQuery(state) {
      return {
        income: state.income,
        educationFundStatus: state.educationFundStatus,
        title: state.title,
      };
    },
  },
};

const getCalculatorQueryParams = () => {
  const query = useRoute().query;
  const income = Math.abs(parseInt(query.income));
  return {
    income: !isNaN(income) ? income : undefined,
    educationFundStatus: educationFundStatuses[query.educationFundStatus],
    title: query.title,
  };
};

const baseState = {
  incomeInput: null,
  income: undefined,
  educationFundStatus: educationFundStatuses.ceiling,
  title: undefined,
  isFormDisabled: false,
};

export { calculator };
