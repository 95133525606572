<template>
  <button
    :class="[
      'style-guide-button',
      { 'style-guide-button--secondary': !isPrimary },
      { 'style-guide-button--small': isSmall },
    ]"
    :type="type"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'StyleGuideButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../shared/style/_colors.scss';

.style-guide-button {
  $base: &;
  border: none;
  outline: none;
  color: $white;
  background: $dark-green;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  letter-spacing: 0.4px;
  transition: all 0.1s linear;

  &:focus,
  &:hover {
    box-shadow: inset 0 -3px darken($dark-green, 2), 0px 2px 6px 3px #0000001f;
    background: lighten($dark-green, 2);
  }

  &--secondary {
    $darken-secondary-green: darken($dark-green, 3);
    background: $white;
    color: $darken-secondary-green;
    box-shadow: 0 0 0 1px $darken-secondary-green;

    &:focus,
    &:hover {
      background: darken($white, 2);
      box-shadow: 0 0 0 1px $darken-secondary-green;
    }
  }

  &--small {
    font-size: 0.8rem;
    border-radius: 3px;
    padding: 2px 4px;
  }
}
</style>
