// init
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

// external elements
import VueMobileDetection from 'vue-mobile-detection';

// page load components
import ArticleSection from '@/components/articles/shared/ArticleSection.vue';
import StyleGuideButton from '@/components/shared/StyleGuideButton.vue';
import ArticleBase from '@/components/articles/shared/ArticleBase.vue';

createApp(App)
  .use(store)
  .use(router)
  .use(VueMobileDetection)
  .component('ArticleSection', ArticleSection)
  .component('StyleGuideButton', StyleGuideButton)
  .component('ArticleBase', ArticleBase)
  .mount('#app');
